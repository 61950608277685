import styled from "styled-components";
import React from "react";
import { BasicLayout } from "../../layouts/BasicLayout";
import SpecialOfferModal from "../../components/Modal/SpecialOfferModal";
import {
  HeroSegment,
  AppPitchSegment,
  TestimonySegment,
} from "../../components/PageSegments/special-offer/landing";
import { SpecialOfferBar } from "../../components/PageSegments/startnow/landing";
import { web_acq_landing_page_view } from "../../services/mixpanel/acquisition-events";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";

const WEB_NO_TRIAL_FLOW = "web_no_trial_flow";
const offerParam = "blackfridayweb2024";
export default () => {
  const { setOnboardingLandingPage } = useOnboardingContext();

  React.useEffect(() => {
    web_acq_landing_page_view();
    sessionStorage.setItem("landing_page", WEB_NO_TRIAL_FLOW);
    sessionStorage.setItem("flow", "startnow");
    setOnboardingLandingPage(WEB_NO_TRIAL_FLOW);
  }, [setOnboardingLandingPage]);

  return (
    <BasicLayout>
      <Main>
        <SpecialOfferBar trial={false} offerParam={offerParam} />
        <HeroSegment offerParam={offerParam} />
        <TestimonySegment />
        <AppPitchSegment />
        <SpecialOfferModal offerParam={offerParam} />
      </Main>
    </BasicLayout>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
