import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { mobileSize } from "../../../../util/variables";
import { TopBanner } from "../../../ui/TopBanner";
import theme from "../../../../util/theme";

export const SpecialOfferBar = ({
  offerParam,
  specialDiscount,
}: {
  customGroup?: string;
  trial?: boolean;
  specialDiscount?: boolean;
  percentOff?: number;
  offerParam?: string | null;
}) => {
  return (
    <TopBanner>
      LIMITED TIME OFFER:{" "}
      <MobileBr />
      {specialDiscount ? 30 : 20}% OFF{" "}
      <Link
        to={`${`/onboarding/questions${offerParam ? `?offer=${offerParam}` : ""}`}`}
        style={{ fontWeight: 500, color: theme.WHITE_COLOR }}
      >
        Get Started
      </Link>{" "}
    </TopBanner>
  );
};

const MobileBr = styled.br`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;
